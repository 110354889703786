import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import LayoutPromotional from "./components/LayoutPromotional";
import LayoutSelfie from "./components/LayoutSelfie";
import Home from "./pages/Home";
import Login from "./pages/Login";
import EditTemplate from "./pages/promotional/EditTemplate";
import ProPoster from "./pages/promotional/ProPoster";
import SelecteTemplate from "./pages/promotional/SelecteTemplate";
import OpenCamera from "./pages/selfie/OpenCamera";
import ProSelfie from "./pages/selfie/ProSelfie";
import SelecteSelfie from "./pages/selfie/SelecteSelfie";
import ProtectectRoute from "./ProtectectRoute";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<ProtectectRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route element={<LayoutPromotional />}>
                <Route path="/selectTemplate" element={<SelecteTemplate />} />
                <Route path="/editTemplate" element={<EditTemplate />} />
                <Route path="/proPoster" element={<ProPoster />} />
              </Route>
              <Route element={<LayoutSelfie />}>
                <Route path="/selecteSelfie" element={<SelecteSelfie />} />
                <Route path="/openCamera" element={<OpenCamera />} />
                <Route path="/proSelfie" element={<ProSelfie />} />
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
