import somprazLogo from './../assets/logos/somprazLogo.png'
import raciperLogo from './../assets/logos/raciperLogo.png'
import brustanLogo from './../assets/logos/brustanLogo.png'
import zapexLogo from './../assets/logos/zapexLogo.png'
import brustanColcibraLogo from './../assets/logos/brustanColcibraLogo.png'
import brustanVoliniLogo from './../assets/logos/brustanVoliniLogo.png'
export const userCountries = [
    {
      name: "Nepal",
      country: "Nepal",
      code: "NP",
      password: "sompraz",
      logo: somprazLogo,
    },
    {
      name: "Myanmar",
      country: "Myanmar",
      code: "MM",
      password: "sompraz",
      logo: somprazLogo,
    },
    {
      name: "Oman",
      country: "Oman",
      code: "OM",
      password: "raciper",
      logo: raciperLogo,
    },
    {
      name: "Sri Lanka",
      country: "SriLanka",
      code: "LKA",
      password: "sompraz",
      logo: somprazLogo,
    },
    {
      name: "Morocco",
      country: "Morocco",
      code: "MAR",
      password: "raciper",
      logo: raciperLogo,
    },
    {
      name: "Bangladesh",
      country: "Bangladesh",
      code: "BD",
      password: "sompraz",
      logo: somprazLogo,
    },
    {
      name: "Nigeria",
      country: "Nigeria",
      code: "NG",
      password: "brustan",
      logo: brustanColcibraLogo,
    },
    {
      name: "Cameroon",
      country: "Cameroon",
      code: "CMR",
      password: "brustan",
      logo: brustanVoliniLogo,
    },
    {
      name: "Ivory coast",
      country: "Ivorycoast",
      code: "CI",
      password: "brustan",
      logo: brustanVoliniLogo,
    },
    {
      name: "Senegal",
      country: "Senegal",
      code: "SEN",
      password: "brustan",
      logo: brustanVoliniLogo,
    },
    {
      name: "Tanzania",
      country: "Tanzania",
      code: "TZ",
      password: "brustan",
      logo: brustanVoliniLogo,
    },
    {
      name: "Zambia",
      country: "Zambia",
      code: "ZM",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Kenya",
      country: "Kenya",
      code: "KE",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Togo",
      country: "Togo",
      code: "TG",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Benin",
      country: "Benin",
      code: "BJ",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Burkina",
      country: "Burkina",
      code: "BF",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Mali",
      country: "Mali",
      code: "ML",
      password: "brustan",
      logo: brustanLogo,
    },
    {
      name: "Mexico",
      country: "Mexico",
      code: "MEX",
      password: "zapex",
      logo: zapexLogo,
    },
    {
      name: "Peru",
      country: "Peru",
      code: "PE",
      password: "sompraz",
      logo: somprazLogo,
    },
  ];
  