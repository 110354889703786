import React from "react";
import SUNLOGO from "./../assets/sun_pharma_logo_black.svg";
import { Link } from "react-router-dom";
import { HiOutlineLogout } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../redux/features/authSlice";
// import SOMPRAZ from "../assets/logos/somprazLogo.png";
import { userCountries } from "../utils";
const Header = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(setLogout());
  };
  let countryImg = userCountries.find((item)=>item.country === user?.country)
  
  return (
    <header className="bg-white py-2 border-b-2 border-b-gray-300 ">
      <div className="px-4 flex items-center justify-between">
        <div className="flex gap-3 items-center justify-center">
          <Link to="/">
            <img src={SUNLOGO} alt="sunpharma" className="w-10" />
          </Link>
        </div>
        {countryImg?.country && <img src={countryImg.logo} alt="sompraz" className="w-52" />}
        <button className="text-2xl text-slate-800" onClick={handleLogout}>
          <HiOutlineLogout />
        </button>
      </div>
    </header>
  );
};

export default Header;
