import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, Lazy, A11y } from "swiper";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import Template1 from "./../../assets/promotional/template-1.png";
import Template2 from "./../../assets/promotional/template-2.png";
import Template3 from "./../../assets/promotional/template-3.png";
import {
  resetPromotion,
  setTemplateName,
} from "../../redux/features/promotionalSlice";

const SelecteTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelect = () => {
    let poster_name = document
      .querySelector(".swiper-slide-active")
      .getAttribute("template-name");
    dispatch(setTemplateName(poster_name));
    navigate("/editTemplate");
  };
  useEffect(() => {
    dispatch(resetPromotion());
  }, [dispatch]);
  return (
    <div className="p-4">
      <div className="font-bold text-white text-center uppercase">
        Select Template
      </div>
      <div className="px-12 py-4 relative">
        <Swiper
          className="bg-white"
          modules={[Navigation, Pagination, A11y, Lazy]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          preloadImages={false}
          lazy={true}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
        >
          <SwiperSlide template-name={`Template1`}>
            <img src={Template1} alt="img" />
          </SwiperSlide>
          <SwiperSlide template-name={`Template2`}>
            <img src={Template2} alt="img" />
          </SwiperSlide>
          <SwiperSlide template-name={`Template3`}>
            <img src={Template3} alt="img" />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-button-prev prev swiperBtn">Prev</div>
        <div className="swiper-button-next next swiperBtn">Next</div>

        <div className="py-4 pb-2">
          <button className="btn w-full" onClick={handleSelect}>
            Select template
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelecteTemplate;
