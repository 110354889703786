import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import TemplateTop1 from "./../../assets/promotional/template-1-top.png";
import TemplateTop2 from "./../../assets/promotional/template-2-top.png";
import TemplateTop3 from "./../../assets/promotional/template-3-top.png";

import HOUR from "./../../assets/promotional/hour.png";
import moment from "moment";
import ICONCONTACT from "./../../assets/promotional/icon-contact.png";
import Loader from "../../components/Loader";

const ProPoster = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    templateName,
    pharmacyName,
    pharmacyLocation,
    pharmacyContact,
    fromTime,
    toTime,
    message,
  } = useSelector((state) => state.promotional);

  const downLoadPoster = () => {
    setLoading(true);
    window.scrollTo(0, 0);
    html2canvas(document.getElementById("templateView"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      onrendered: function (canvas) {
        document.body.appendChild(canvas);
        window.scrollTo(0, 0);
      },
    })
      .then(async (canvas) => {
        var myImage = canvas.toDataURL("image/jpeg", 0.8);
        // setViewImg(myImage);
        console.log(myImage);
        const link = document.createElement("a");
        link.href = myImage;
        link.target = "_blank";
        link.setAttribute("download", "image.jpeg");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        alert("oops, something went wrong!", error);
      });
  };
  useEffect(() => {
    if (
      !templateName ||
      !pharmacyName ||
      !pharmacyLocation ||
      !pharmacyContact
    ) {
      navigate("/");
    }
  }, [navigate, templateName, pharmacyName, pharmacyLocation, pharmacyContact]);
  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="p-4">
        <div
          className="customBg shadow-md max-w-[320px] w-full flex flex-col mx-auto py-4"
          id="templateView"
        >
          <div className="text-center font-bold uppercase text-[#d92a1c] pt-2 px-6 text-3xl leading-7">
            {pharmacyName}
          </div>
          <div className="mt-4 relative">
            {templateName === "Template1" && (
              <img src={TemplateTop1} alt="template" className="w-full block" />
            )}
            {templateName === "Template2" && (
              <img src={TemplateTop2} alt="template" className="w-full block" />
            )}
            {templateName === "Template3" && (
              <>
                <div className="break-words leading-4 text-white w-64 mx-auto bg-[#096200] absolute left-0 right-0 top-1 p-4 text-sm h-24">
                  {message}
                </div>
                <img
                  src={TemplateTop3}
                  alt="template"
                  className="w-full block"
                />
              </>
            )}
          </div>
          <div className="px-8 mt-4">
            <div className="flex flex-col divide-y divide-blue-300 uppercase">
              <div className="flex items-center justify-center gap-4 py-1 font-bold text-2xl border-t border-t-brand-600 border-b border-b-brand-600 text-brand-600">
                <img src={ICONCONTACT} alt="iconContact" className="w-5" />{" "}
                {pharmacyContact}
              </div>
              <div className="flex items-center justify-center gap-4 py-1 text-gray-600 font-bold text-center leading-5">
                {pharmacyLocation}
              </div>
            </div>
          </div>
          {(templateName === "Template1" || templateName === "Template3") && (
            <div className="relative">
              <div className="text-brand-600 font-bold absolute right-14 bottom-0 text-[18px]">
                {moment(fromTime, "hh:mm:ss").format("HH:mm A")}-
                {moment(toTime, "hh:mm:ss").format("HH:mm A")}
              </div>
              <img src={HOUR} alt="footer" className="w-full block" />
            </div>
          )}
        </div>
      </div>
      <div className="fixed bg-black/40 w-full left-0 right-0 bottom-0 p-4 text-center flex gap-4 items-start justify-center">
        <button onClick={() => navigate(-1)} className="btn" disabled={loading}>
          Back
        </button>
        <button onClick={downLoadPoster} className="btn" disabled={loading}>
          Download
        </button>
      </div>
    </>
  );
};

export default ProPoster;
