import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/authSlice";
import promotionalSlice from "./features/promotionalSlice";
import selfieSlice from "./features/selfieSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    promotional: promotionalSlice,
    selfie: selfieSlice,
  },
});
