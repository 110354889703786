import React from "react";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, Lazy, A11y } from "swiper";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import selfie1 from "./../../assets/selfie/selfie1.png";
import selfie2 from "./../../assets/selfie/selfie2.png";
import { setTemplateName } from "../../redux/features/selfieSlice";

const SelecteSelfie = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSelect = () => {
    let poster_name = document
      .querySelector(".swiper-slide-active")
      .getAttribute("template-name");
    dispatch(setTemplateName(poster_name));
    navigate("/openCamera");
  };
  return (
    <div className="p-4">
      <div className="font-bold text-white text-center uppercase">
        Select Template
      </div>
      <div className="px-12 py-4 relative">
        <Swiper
          className=" bg-white"
          modules={[Navigation, Pagination, A11y, Lazy]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{ clickable: true }}
          preloadImages={false}
          lazy={true}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
        >
          <SwiperSlide template-name={`selfie1`}>
            <img data-src={selfie1} className="swiper-lazy" alt="img" />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
          <SwiperSlide template-name={`selfie2`}>
            <img data-src={selfie2} className="swiper-lazy" alt="img" />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          </SwiperSlide>
        </Swiper>
        <div className="swiper-button-prev prev swiperBtn">Prev</div>
        <div className="swiper-button-next next swiperBtn">Next</div>

        <div className="py-4 pb-2">
          <button className="btn w-full" onClick={handleSelect}>
            Select template
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelecteSelfie;
