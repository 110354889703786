import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import SMARTSELFIE from "./../assets/images/smartSelfieBtn.png?v1";
import SMARTDIGITALFLY from "./../assets/images/smartDigitalFlyers.png?v1";

const Home = () => {
  return (
    <>
      <Header />
      <div className="px-4 py-12">
        <div className="flex flex-col gap-8 items-center justify-center ">
          <Link to="/selecteSelfie">
            <img src={SMARTSELFIE} alt="Smart Selfie" className="w-[300px]" />
          </Link>
          <Link to="/selectTemplate">
            <img
              src={SMARTDIGITALFLY}
              alt="Smart Promotional"
              className="w-[300px]"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
