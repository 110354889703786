import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const user = JSON.parse(localStorage.getItem("sonprazUser"));

const initialState = {
  user: user ? user : null,
  loading: false,
  error: "",
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ userData, navigate, toast }, { rejectWithValue }) => {
    try {
      navigate("/");
      toast.success("Login successfully");
      return userData;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogout: (state) => {
      state.user = null;
      localStorage.removeItem("sonprazUser");
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("sonprazUser", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    [loginUser.pending]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
export const { setLogout } = authSlice.actions;
export default authSlice.reducer;
