import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
const ProtectectRoute = () => {
  const { user } = useSelector((state) => state.auth);
  let location = useLocation();
  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default ProtectectRoute;
