import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import SUNLOGO from "./../assets/sun_pharma_logo_white.svg";
import { loginUser } from "../redux/features/authSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { userCountries } from "../utils";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({
    name: "",
    country: "",
    contact: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userData.name || !userData.country || !userData.contact) {
      return toast.error("Please fill all the fields");
    }
    dispatch(loginUser({ userData, navigate, toast }));
  };

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, from, navigate]);
  return (
    <div className="screenHeight pageBg flex items-center justify-around flex-col px-8 ">
      <div className="mt-auto w-40">
        <img src={SUNLOGO} alt="sunpharma" />
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 w-full mx-auto rounded-md shadow-2xl mt-auto mb-auto"
      >
        <div className="form-group">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            value={userData.name}
            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="country">
            Country
          </label>
          <select
            className="form-control"
            type="text"
            id="country"
            value={userData.country}
            onChange={(e) =>
              setUserData({ ...userData, country: e.target.value })
            }
          >
            <option disabled="" value="">
              Select
            </option>
            {
              userCountries?.length > 0 && userCountries.map((item)=>{
                return <option key={item.country} value={item.country}>{item.country}</option>
              })
            }
            {/* <option value="Bangladesh">Bangladesh</option>
            <option value="Cameroon">Cameroon</option>
            <option value="IVC">IVC</option>
            <option value="Kenya">Kenya</option>
            <option value="Mexico">Mexico</option>
            <option value="Morocco">Morocco</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Nepal">Nepal</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Oman">Oman</option>
            <option value="Peru">Peru</option>
            <option value="Senegal">Senegal</option>
            <option value="South Africa">South Africa</option>
            <option value="Tanzania">Tanzania</option>
            <option value="Zambia">Zambia</option> */}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="contact">
            Contact
          </label>
          <input
            className="form-control"
            type="text"
            id="contact"
            value={userData.contact}
            onChange={(e) =>
              setUserData({ ...userData, contact: e.target.value })
            }
          />
        </div>
        <div className="form-group !mb-0">
          <button type="submit" className="btn w-full">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
