import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import selfie1 from "./../../assets/selfie/selfie1.png";
import selfie2 from "./../../assets/selfie/selfie2.png";
import { useDispatch, useSelector } from "react-redux";
import { setPhoto } from "../../redux/features/selfieSlice";

const OpenCamera = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateName } = useSelector((state) => state.selfie);
  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: "user",
    width: { min: 1280, ideal: 1920, max: 2560 },
    height: { min: 720, ideal: 1080, max: 1440 },
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(setPhoto(imageSrc));
    navigate("/proSelfie");
  }, [webcamRef, dispatch, navigate]);

  useEffect(() => {
    if (!templateName) {
      navigate("/proSelfie");
    }
  }, [navigate, templateName]);
  return (
    <>
      <div className="p-8">
        <div className="w-full relative">
          {templateName && templateName === "selfie1" && (
            <img src={selfie1} alt="poster" className="absolute w-full z-10" />
          )}
          {templateName && templateName === "selfie2" && (
            <img src={selfie2} alt="poster" className="absolute w-full z-10" />
          )}
          <div className="aspect-[9/16] customVideo relative">
            <Webcam
              videoConstraints={videoConstraints}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </div>
        </div>
      </div>
      <div className="fixed bg-black/40 w-full left-0 right-0 bottom-0 p-4 text-center flex gap-4 items-start justify-center z-20">
        <button onClick={() => navigate(-1)} className="btn">
          Back
        </button>
        <button onClick={capture} className="btn">
          Capture Photo
        </button>
      </div>
    </>
  );
};

export default OpenCamera;
