import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setTemplateData } from "../../redux/features/promotionalSlice";
import TemplateTop1 from "./../../assets/promotional/template-1-top.png";
import TemplateTop2 from "./../../assets/promotional/template-2-top.png";
import TemplateTop3 from "./../../assets/promotional/template-3-top.png";
const EditTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    templateName,
    pharmacyName,
    pharmacyLocation,
    pharmacyContact,
    fromTime,
    toTime,
    message,
  } = useSelector((state) => state.promotional);
  const [tempData, setTempData] = useState({
    pharmacyName: pharmacyName,
    pharmacyLocation: pharmacyLocation,
    pharmacyContact: pharmacyContact,
    fromTime: fromTime,
    toTime: toTime,
    message: message,
  });

  const messageHandleChange = (e) => {
    if (e.target.value.length === 100) {
      toast.error(`Message is limited to 100 characters only`);
      return false;
    }
    setTempData({ ...tempData, message: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !tempData.pharmacyName ||
      !tempData.pharmacyLocation ||
      !tempData.pharmacyLocation
    ) {
      return toast.error("Please fill all the fields");
    }
    if (templateName === "Template1" || templateName === "Template3") {
      if (!tempData.fromTime || !tempData.toTime) {
        return toast.error("Please fill all the fields");
      }
    }
    if (templateName === "Template3" && !tempData.message) {
      return toast.error("Please fill all the fields");
    }
    if (tempData.message && tempData.message.length > 100) {
      return toast.error(
        `Message is limited to 100 characters, you entered ${tempData.message.length} characters`
      );
    }
    console.log(tempData);
    dispatch(setTemplateData(tempData));
    navigate("/proPoster");
  };
  useEffect(() => {
    if (!templateName) {
      navigate("/");
    }
  }, [navigate, templateName]);
  return (
    <div className="p-4 space-y-6">
      <div className="w-full px-10 relative">
        <div className="bg-[#c6edf7] py-3">
          {templateName === "Template1" && (
            <img src={TemplateTop1} alt="template" className="w-full block" />
          )}
          {templateName === "Template2" && (
            <img src={TemplateTop2} alt="template" className="w-full block" />
          )}
          {templateName === "Template3" && (
            <img src={TemplateTop3} alt="template" className="w-full block" />
          )}
        </div>
      </div>
      <div className="p-6 bg-white rounded-md">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="pharmacyName" className="form-label">
              Pharmacy name
            </label>
            <input
              className="form-control"
              type="text"
              id="pharmacyName"
              value={tempData.pharmacyName}
              onChange={(e) =>
                setTempData({ ...tempData, pharmacyName: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="pharmacyLocation" className="form-label">
              Pharmacy location
            </label>
            <input
              className="form-control"
              type="text"
              id="pharmacyLocation"
              value={tempData.pharmacyLocation}
              onChange={(e) =>
                setTempData({ ...tempData, pharmacyLocation: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="pharmacyContact" className="form-label">
              Pharmacy contact
            </label>
            <input
              className="form-control"
              type="text"
              id="pharmacyContact"
              value={tempData.pharmacyContact}
              onChange={(e) =>
                setTempData({ ...tempData, pharmacyContact: e.target.value })
              }
            />
          </div>
          {templateName === "Template3" && (
            <div className="form-group">
              <label
                htmlFor="message"
                className="form-label !flex w-full justify-between items-center"
              >
                Message
                <span className="text-xs text-rose-700">
                  *Only 100 characters allow
                </span>
              </label>
              <textarea
                className="form-control"
                id="message"
                value={tempData.message}
                onChange={messageHandleChange}
              ></textarea>
            </div>
          )}

          {(templateName === "Template1" || templateName === "Template3") && (
            <div className="flex gap-4">
              <div className="form-group w-full">
                <label htmlFor="fromTime" className="form-label">
                  Open from
                </label>
                <input
                  className="form-control"
                  type="time"
                  id="fromTime"
                  value={tempData.fromTime}
                  onChange={(e) =>
                    setTempData({ ...tempData, fromTime: e.target.value })
                  }
                />
              </div>
              <div className="form-group w-full">
                <label htmlFor="toTime" className="form-label">
                  Open till
                </label>
                <input
                  className="form-control"
                  type="time"
                  id="toTime"
                  value={tempData.toTime}
                  onChange={(e) =>
                    setTempData({ ...tempData, toTime: e.target.value })
                  }
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <button type="submit" className="btn !w-full">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTemplate;
