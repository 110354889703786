import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateName: "",
  photo: "",
};

const selfieSlice = createSlice({
  name: "selfie",
  initialState,
  reducers: {
    setTemplateName: (state, action) => {
      state.templateName = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
  },
});

export const { setTemplateName, setPhoto } = selfieSlice.actions;

export default selfieSlice.reducer;
