import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";

import selfie1 from "./../../assets/selfie/selfie1.png";
import selfie2 from "./../../assets/selfie/selfie2.png";
import Loader from "../../components/Loader";

const ProSelfie = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { templateName, photo } = useSelector((state) => state.selfie);

  const downLoadPoster = () => {
    setLoading(true);
    window.scrollTo(0, 0);
    html2canvas(document.getElementById("templateView"), {
      allowTaint: true,
      useCORS: true,
      logging: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      onrendered: function (canvas) {
        document.body.appendChild(canvas);
        window.scrollTo(0, 0);
      },
    })
      .then(async (canvas) => {
        var myImage = canvas.toDataURL("image/jpeg", 0.8);
        // setViewImg(myImage);
        console.log(myImage);
        const link = document.createElement("a");
        link.href = myImage;
        link.target = "_blank";
        link.setAttribute("download", "image.jpeg");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        alert("oops, something went wrong!", error);
      });
  };
  useEffect(() => {
    if (!templateName || !photo) {
      navigate("/");
    }
  }, [navigate, templateName, photo]);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="p-8">
        <div className="shadow-md flex flex-col" id="templateView">
          <div className="w-full relative">
            {templateName && templateName === "selfie1" && (
              <img
                src={selfie1}
                alt="poster"
                className="relative w-full z-10"
              />
            )}
            {templateName && templateName === "selfie2" && (
              <img
                src={selfie2}
                alt="poster"
                className="relative w-full z-10"
              />
            )}
            {photo && (
              <img
                src={photo}
                alt="photouser"
                className="absolute top-0 z-[1]"
                style={{ transform: "scaleX(-1)" }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="fixed bg-black/40 w-full left-0 right-0 bottom-0 p-4 text-center flex gap-4 items-start justify-center z-20">
        <button onClick={() => navigate(-1)} className="btn" disabled={loading}>
          Back
        </button>
        <button onClick={downLoadPoster} className="btn" disabled={loading}>
          Download
        </button>
      </div>
    </>
  );
};

export default ProSelfie;
