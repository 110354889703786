import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templateName: "",
  pharmacyName: "",
  pharmacyLocation: "",
  pharmacyContact: "",
  fromTime: "",
  toTime: "",
  message: "",
};

const promotionalSlice = createSlice({
  name: "promotional",
  initialState,
  reducers: {
    resetPromotion: (state) => {
      return initialState;
    },
    setTemplateName: (state, action) => {
      state.templateName = action.payload;
    },
    setTemplateData: (state, action) => {
      state.pharmacyName = action.payload.pharmacyName;
      state.pharmacyLocation = action.payload.pharmacyLocation;
      state.pharmacyContact = action.payload.pharmacyContact;
      state.fromTime = action.payload.fromTime;
      state.toTime = action.payload.toTime;
      state.message = action.payload.message;
    },
  },
});

export const { resetPromotion, setTemplateName, setTemplateData } =
  promotionalSlice.actions;

export default promotionalSlice.reducer;
